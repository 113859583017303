<!-- 售后服务 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部 搜索 -->
		<button-search class="pt-3">
			<template #right>
				<div class="d-flex align-items-center">
					<el-date-picker v-model="search.time" type="daterange" range-separator="至" start-placeholder="开始日期"
					 end-placeholder="结束日期"  size="mini">
					</el-date-picker>
					<el-input v-model="search.keyword" size="mini" placeholder="请输入订单号" style="width: 200px;" class="mx-2"></el-input>
					<el-button type="info" size="mini">搜索</el-button>
				</div>
			</template>
		</button-search>

		<!-- 商品列表 -->
		<el-table :data="tableData" style="width: 100%" border class="mt-3" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>

			<!-- 商品信息 -->
			<el-table-column prop="name" label="商品信息">
			</el-table-column>

			<!-- 商品清单 -->
			<el-table-column label="商品清单" align="center" prop="username">
			</el-table-column>

			<!-- 订单金额 -->
			<el-table-column label="订单金额" align="center" prop="price">
			</el-table-column>

			<!-- 收货信息 -->
			<el-table-column label="收货信息" align="center" prop="price">
			</el-table-column>

			<!-- 买家 -->
			<el-table-column label="买家" align="center" prop="content">
			</el-table-column>

			<!-- 交易状态 -->
			<el-table-column label="交易状态" align="center" prop="code">
			</el-table-column>

			<!-- 创建时间 -->
			<el-table-column label="创建时间" align="center" prop="create_time">
			</el-table-column>

			<!-- 操作 -->
			<el-table-column label="操作" align="center" width="150">
				<!-- scope拿到当前位置的索引 -->
				<template slot-scope="scope">
					<el-button-group>
						<el-button type="danger" size="mini" plain @click="deleteItem(scope)">删除</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<!-- 底部内容 -->
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<!-- 底部分页 -->
			<div style="flex: 1;" class="px-2">
				<el-pagination :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
				 :total="400">
				</el-pagination>
			</div>
		</el-footer>
	</div>
</template>

<script>
	import buttonSearch from "@/components/common/button-search.vue" // 引入 按钮、搜索、高级搜索组件
	export default {
		// 引入 注册
		components: {
			buttonSearch
		},
		data() {
			return {
				tableData: [],
				multipleSelection: [],
				currentPage: 1,

				search: {
					time: "", //日期时间
					keyword: "" //关键字
				}
			}
		},
		// 生命周期，调用__getData生成的数据
		created() {},
		// 生命周期监听页面
		methods: {
			// 多选触发判断
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 删除单个规格卡片
			deleteItem(scope) {
				this.$confirm('是否要删除该规格', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.tableData.splice(scope.$index, 1)
					this.$message({
						message: '删除成功',
						type: 'success'
					})
				})
			},
		}
	}
</script>

<style>
	.sku-list-item>i {
		display: none;
		cursor: pointer;
	}

	.sku-list-item:hover {
		background-color: #F4F4F4;
	}

	.sku-list-item:hover>font {
		display: none;
	}

	.sku-list-item:hover>i {
		display: inline-block;
	}
</style>

